import React from "react"

import "./ogado-game.scss"

const OgadoGame = ({ lang, game }) => (
  <div className="ogado__game">
    <div className="ogado__game-header">
      <h3 className="ogado__game-header-title">{game.name}</h3>
      <span className="ogado__game-header-subtitle">
        <span className="ogado__game-header-subtitle__added">
          {lang === "it" ? "Aggiunto" : "Added"} {game.timesAdded}{" "}
          {game.timesAdded === 1
            ? lang === "it"
              ? "volta"
              : "time"
            : lang === "it"
            ? "volte"
            : "times"}
        </span>
      </span>
    </div>

    <div className="row content__content-gallery">
      <div
        style={{
          backgroundImage: `url('https:${game.cover?.url?.replace('t_thumb', 't_original')}')`,
        }}
        className="content__content-image"
      >
        <div className="content__content-image-description">
          <span className="genre">
            <strong>{game.genres?.length ? game.genres[0]?.name : ""}</strong>
          </span>
          <span className="year">{game.involved_companies?.find(company => company.developer)?.company?.name ?? ''}</span>
        </div>
      </div>
      {game.screenshots?.map((image, index) => (
        <img
          key={image.url}
          src={`https:${image.url?.replace('t_thumb', 't_original')}`}
          className="content__content-image"
          alt={`${game.title} immagine ${index}`}
        ></img>
      ))}
    </div>

    <span style={{ marginTop: '2rem', paddingLeft: "10px" }}>
      <strong>{lang === 'it' ? 'Trama' : 'Overview'}</strong>
    </span>
    <p className="content__content-overview">{game.summary}</p>

    <span style={{ paddingLeft: "20px" }}>
      <strong>{lang === "it" ? "Disponibile su" : "Available on"}</strong>
    </span>

    <div className="content__platforms-list" style={{ overflowX: "auto" }}>
      {game?.platforms?.filter(platform => platform.logo !== null).map(platform => (
        <a
          href={platform.link}
          target="_blank"
          rel="noopener noreferrer"
          className="content__platforms-platform"
        >
          <div style={{ backgroundImage: `url(${platform.logo.url})` }}></div>
        </a>
      ))}
    </div>

    <span style={{ marginTop: '2rem', paddingLeft: "20px" }}>
      <strong>{lang === "it" ? "Video" : "Videos"}</strong>
    </span>

    <div className="ogado__game-videos">
      {game.videos?.map(video => (
        <iframe
          key={video.key}
          className="ogado__game-videos-video"
          src={`https://www.youtube.com/embed/${video.video_id}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ))}
    </div>
  </div>
)

export default OgadoGame
